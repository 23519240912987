@import '../configuration/config';

.news-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .news-loading-text {
    text-align: center;
  }
}
