@import '../configuration/config';

.partners-container {
  max-width: $app-max-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  .partner-intro-text {
    max-width: 1200px;
    @include MaxMQ(md) {
      font-size: 14px !important;
      width: 125%;
    }
  }
  .partners-icons {
    max-width: 1400px;
    height: auto;
    @include MaxMQ(sm) {
      align-items: center;
    }
  }
}