@import '../configuration/config';

.pop-up-container {
  border-radius: 2px;
  z-index: 11;
  position: fixed;
  right: 40px;
  bottom: 40px;
  @include MaxMQ(sm) {
    right: 20px;
    bottom: 50px;
  }
}
