@import "../configuration/config";

.member-card {
  max-height: 730px;
  max-width: 320px;
  .card-name {
    letter-spacing: 0.15rem;
    @include MaxMQ(sm) {
      font-size: 20px !important;
    }
  }
  .avatar {
    max-height: 323px;
  }
  .member-intro {
    height: 250px;
    overflow: auto;
    z-index: 11;
    background-color: white;
    opacity: 0.8;
  }
}
@include MaxMQ(md) {
  .member-card {
    width: 150px;
  }
}
@include MaxMQ(lgr) {
  .member-card:nth-child(2) {
    padding-top: 22px !important;
  }
}
@media only screen and (min-width: 1370px) {
  .member-card:nth-child(2n) {
    margin-top: 200px;
    margin-bottom: -180px;
  }
  //When last line members numbers are not even
  .member-card:last-child {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 1370px) {
  .member-card:nth-child(3n + 2) {
    margin-top: 200px;
    margin-bottom: -180px;
  }
  .member-card:last-child {
    margin-top: 0px;
  }
  // .member-card:nth-child(17) {
  //   margin-top: 0px;
  //   margin-bottom: 0px;
  // }
}

@media only screen and (max-width: 1047px) {
  .member-card:nth-child(n + 1) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
