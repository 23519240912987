@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-slider {
  .slider-image-container {
    width: 100%;
    .slider-image {
      width: 100%;
    }
  }
  .lazy-load-image-background {
    width: 100%;
  }
}
