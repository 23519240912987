@import '../configuration/config';

.nav-bar {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  background-color: white;
  box-shadow: 5px 5px 20px rgba(255, 255, 255, 0.6);
  @include MaxMQ(sm) {
    overflow: auto;
    height: 100%;
    left: -600px;
    background-color: white;
    transition: left 0.5s;
    justify-content: space-evenly !important;
    margin-bottom: 40px;
    box-shadow: none;
  }
  .nav-item-text {
    @include MQ(xs) {
      font-size: 20px;
    }
    @include MQ(md-lg) {
      font-size: 24px;
    }
  }
  .nav-item-text-5 {
    transition: letter-spacing 0.5s !important;
  }
  .nav-item-text-5:hover {
    letter-spacing: 6px !important;
  }
}

.open-nav-bar {
  left: 0;
  transition: left 0.5s;
}

.nav-item {
  text-decoration: none;
  color: black;
}

.nav-bar-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  box-shadow: 1px 1px 20px white;
  background-color: white;
  .nav-close-button {
    transform: rotate(45deg);
  }
  .nav-mobile-button {
    width: 30px;
    height: 18px;
    position: relative;
    margin: 0px auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  .nav-mobile-button span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: black;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  .nav-mobile-button span:nth-child(1) {
    top: 0px;
  }
  .nav-mobile-button span:nth-child(2),.nav-mobile-button span:nth-child(3) {
    top: 8px;
  }
  .nav-mobile-button span:nth-child(4) {
    top: 16px;
  }
  .nav-mobile-button.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  .nav-mobile-button.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  .nav-mobile-button.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .nav-mobile-button.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  @include MQ(sm) {
    display: none !important;
  }
}
