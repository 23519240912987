@import '../configuration/config';

.map-container {
    .map-marker {
      color: rgb(190, 81, 81) !important;
    }
    .map-popup {
      width: 160px;
      height: auto;
    }
    height: 50vh;
}