@import '../configuration/config';

.partner-box {
  width: 300px;
  height: 300px;
  filter: grayscale(100%);
  @include MaxMQ(lg) {
    filter: grayscale(0);
  }
  @include MaxMQ(sm) {
    border: 0 !important;
    border-bottom: $border-main !important;
    justify-content: start !important;
    width: 95px;
    height: 90px;
    margin-bottom: 60px !important;
    .partner-box-image {
      width: 75%;
    }
  }
  @media only screen and (width: 360px) {
    width: 90px;
    height: 90px;
  }
  @media only screen and (width: 414px) {
    width: 100px;
    height: 100px;
    .partner-box-image {
      width: 80%;
    }
  }
}
.partner-box:nth-child(n + 2) {
  margin-left: -1px;
  margin-bottom: -1px;
}
.partner-box:nth-child(-n + 5) {
  margin-bottom: -1px;
}
