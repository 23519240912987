@import '../configuration/config';

.home {
  padding-top: 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include MaxMQ(md) {
    height: 96%;
  }
  @include MQ(xxl) {
    width: 100%;
    max-width: 3400px;
  }
  .home_header {
    position: relative;
    width: 92%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .home_letter {
      position: absolute;
      @include MQ(sm) {
        width: 15%;
      }
      @media only screen and (max-width: 1024px) {
        width: 18%;
      }
      @include MQ(lgr) {
        width: 13%;
      }
    }
    .home_c {
      @media only screen and (max-width: 1024px) {
        bottom: 30% !important;
      }
      @include MQ(sm) {
        bottom: 14%;
        left: -4%;
      }
      @include MQ(lgr) {
        left: -3%;
        bottom: 12%;
      }
      @media only screen and (min-width: 2083px) {
        bottom: 10%
      }
    }
    .home_a {
      @media only screen and (max-width: 1024px) {
        bottom: 40% !important;
      }
      @include MQ(sm) {
        bottom: 33%;
        left: 18.5%;
      }
      @include MQ(lgr) {
        left: 19.5%;
        bottom: 27%;
      }
      @media only screen and (min-width: 2083px) {
        bottom: 25%
      }
    }
    .home_d {
      @media only screen and (max-width: 1024px) {
        bottom: 50% !important;
      }
      @include MQ(sm) {
        bottom: 52%;
        left: 41%;
      }
      @include MQ(lgr) {
        left: 42%;
        bottom: 42%;
      }
      @media only screen and (min-width: 2083px) {
        bottom: 40%
      }
    }
    .home_dd {
      @media only screen and (max-width: 1024px) {
        bottom: 60% !important;
      }
      @include MQ(sm) {
        bottom: 71%;
        left: 63.5%;
      }
      @include MQ(lgr) {
        left: 64.5%;
        bottom: 57%;
      }
      @media only screen and (min-width: 2083px) {
        bottom: 55%
      }
    }
    .home_9 {
      @media only screen and (max-width: 1024px) {
        bottom: 57% !important;
      }
      @include MQ(sm) {
        bottom: 55%;
        left: 84%;
      }
      @include MQ(lgr) {
        left: 87%;
        bottom: 54%;
      }
      @media only screen and (min-width: 2083px) {
        bottom: 50%
      }
    }
    @include MaxMQ(sm) {
      display: none;
    }
  }
  .home-header-mobile {
    display: none;
    @include MaxMQ(sm) {
      z-index: -1;
      display: flex;
      height: 100%;
      overflow: auto;
      .letter-section-one {
        position: relative;
        height: 20%;
        .letter-section-one-c {
          left: 0;
          top: 97.5px;
        }
        .letter-section-one-a {
          left: 20%;
        }
        .letter-section-one-d {
          left: 40%;
          top: 44px;
        }
        .letter-section-one-dd {
          left: 60%;
          top: 44px;
        }
        .letter-section-one-9 {
          left: 80%;
        }
      }
      .letter-section-two {
        position: relative;
        height: 30%;
        bottom: 1%;
        .letter-section-two-c {
          left: 0;
          top: 34%;
        }
        .letter-section-two-a {
          left: 30%;
        }
        .letter-section-two-d {
          left: 50%;
          top: 10%;
        }
        .letter-section-two-dd {
          left: 50%;
          top: 44%;
        }
        .letter-section-two-9 {
          left: 80%;
        }
      }
      .letter-section-three {
        position: relative;
        height: 33%;
        .letter-section-three-c {
          left: 0;
          bottom: 0;
        }
        .letter-section-three-a {
          left: 20%;
          bottom: 15%;
        }
        .letter-section-three-d {
          left: 40%;
          bottom: 30%;
        }
        .letter-section-three-dd {
          left: 60%;
          bottom: 45%;
        }
        .letter-section-three-9 {
          left: 80%;
          bottom: 40%;
        }
      }
      >div >img {
        width: 55px;
        position: absolute;
      }
      .mobile-home-text {
        position: relative;
        bottom: 0;  
      }
      .mobile-text-content-one {
        position: relative;
        bottom: 2%;
      }
    }
  }
}