@import '../configuration/config';

.feature-category {
  @include MaxMQ(sm) {
    max-width: 576px;
    height: 600px;
    margin-bottom: 0px !important;
  }
  position: relative;
  height: 750px;
  margin-bottom: 200px;
  .feature-text {
    font-size: 65px;
    letter-spacing: 0.15em;
    line-height: 1;
    > span {
      z-index: 11;
    }
    @include MaxMQ(xlr) {
      letter-spacing: normal;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 32px;
      letter-spacing: normal !important;
    }
    @include MaxMQ(sm) {
      font-size: 20px;
      letter-spacing: normal !important;
    }
  }
  .feature-text-insturment {
    @include MaxMQ(sm) {
      margin-bottom: 0px !important;
    }
  }
  .feature-text-one {
    @include MaxMQ(sm) {
      font-size: 12px !important;
    }
  }
  .feature-text-title {
    letter-spacing: 1em;
    @media only screen and (max-width: 1024px) {
      letter-spacing: normal !important;
    }
  }
  .feature-text-study {
    text-align: right;
    margin-right: 1em;
    @include MaxMQ(sm) {
      margin-right: 0;
    }
  }
  .feature-piano {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    @include MaxMQ(sm) {
      top: 50%;
      width: 100%;
    }
  }
  .feature-piano-0 {
    @include MaxMQ(sm) {
      top: 60%;
    }
  }
  .feature-piano-1 {
    @include MaxMQ(sm) {
      top: 60%;
    }
  }
  .feature-piano-2 {
    @include MaxMQ(sm) {
      top: 55%;
    }
  }
  .feature-piano-3 {
    @include MaxMQ(sm) {
      top: 62%;
    }
  }
  .feature-piano-4 {
    @include MaxMQ(sm) {
      top: 70%;
    }
  }
  .feature-piano-5 {
    @include MaxMQ(sm) {
      top: 65%;
      left: 30%;
      > img {
        width: 150% !important;
      }
    }
  }
  .feature-piano-6 {
    @include MaxMQ(sm) {
      top: 60%;
      left: 12%;
      > img {
        width: 180% !important;
      }
    }
  }
  .feature-notes {
    position: absolute;
    bottom: 30px;
    @include MaxMQ(sm) {
      list-style: none;
      top: 13%;
    }
  }
  .feature-notes > ul > li {
    @include MaxMQ(sm) {
      font-size: 12px !important;
      padding-left: 0;
    }
  }
  .feature-notes > ul > li::before {
    @include MaxMQ(sm) {
      content: none;
    }
  }
  .feature-notes > ul {
    @include MaxMQ(sm) {
      padding: 0;
    }
  }
  .feature-text-note-section-one {
    @include MaxMQ(sm) {
      margin-left: 0px !important;
      display: block;
    }
  }
  .feature-text-green {
    color: #6fa72f;
  }
  .feature-text-sight-sing {
    @include MaxMQ(sm) {
      margin-right: 60px !important;
    }
  }
  .feature-text-blues {
    @include MaxMQ(sm) {
      margin-left: 0px !important;
      font-size: 12px !important;
    }
  }
  .text-song-decon {
    @include MaxMQ(xl) {
      letter-spacing: normal;
    }
  }
  .business-text {
    margin-left: 300px;
    @include MaxMQ(lgr) {
      margin-left: 120px;
    }
    @include MaxMQ(sm) {
      margin-left: 60px;
    }
  }
  .feature-creative-text {
    margin-left: 120px;
    @media only screen and (max-width: 360px) {
      margin-left: 80px;
    }
  }
}
