@import "../configuration/config";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.slider-case-container {
  @include MaxMQ(sm) {
    width: 100%;
  }
  .slick-slider {
    padding-left: 67.5px;
    padding-right: 67.5px;
    @include MaxMQ(sm) {
      padding-right: 0px;
      padding-left: 0px;
    }
    .custom-slick-arrow {
      width: 40px;
      height: 80px;
      position: absolute;
      top: 100px;
    }
    .custom-slick-arrow-features {
      width: 40px;
      height: 80px;
      position: absolute;
      top: 300px;
    }
    .custom-prev-slick-arrow {
      left: -20px;
      @include MaxMQ(sm) {
        display: none;
      }
    }
    .custom-next-slick-arrow {
      right: 0px;
      @include MaxMQ(sm) {
        display: none;
      }
    }
    .slick-slide {
      @include MaxMQ(md) {
        max-width: 720px;
        max-height: 100%;
      }
    }
    .slick-dots > li {
      color: grey;
    }
    .slick-dots > li::before {
      display: none;
    }
  }
  .slider-case-image {
    max-width: 200px;
    @include MaxMQ(sm) {
      width: 120px;
    }
  }
}

.video-cover-container {
  position: relative;

  .play-icon {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(
      -50%,
      -50%
    ); // Ensure centering based on the icon's dimensions
    color: white;
    pointer-events: none; // Prevent the icon from blocking interactions with the link
    font-size: 1.5rem;
    opacity: 0.7;
    transition: opacity 0.4s ease-in-out;
    @include MaxMQ(xxl) {
      left: 50%;
    }
  }

  &:hover .play-icon {
    opacity: 1;
    font-size: 2rem;
  }
}

.video-cover-container:hover {
  cursor: pointer;
}
