@import '../configuration/config';

.advantages-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .advantages-section {
    max-width: 1790px;
  }
}