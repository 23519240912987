@import '../configuration//config';

.cases-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .cases-example {
    flex-direction: column;
    @include MQ(lg) {
      flex-direction: row;
    }
    @media only screen and (width: 1024px) {
      flex-direction: column;
    }
    @include MaxMQ(md) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}
