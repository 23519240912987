@import "../configuration/config";

.contact-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-tel {
    text-decoration: none;
    color: black;
  }
  .contact-email {
    text-decoration: none;
    color: black;
  }
  .qr-code-section {
    @include MaxMQ(sm) {
      justify-content: space-between;
    }
  }
  .qr-code-image {
    width: 100px;
  }
}
.address-detail {
  position: relative;
}
.address-section {
  width: 45%;
  margin: 1%;
  @include MaxMQ(md) {
    width: 100%;
    margin-bottom: 40px;
  }
}
.address-photo-container {
  width: 100%;
  > span {
    width: 100%;
  }
}
.address-photo {
  width: 100%;
}
.divide-line {
  position: absolute;
  height: 1px;
  top: 86%;
  z-index: 11;
  @include MaxMQ(md) {
    display: none;
  }
}
