@import '../configuration/config';

.feature-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .feature-image-container {
    width: 45%;
    @include MaxMQ(md) {
      width: 100%;
    }
  }
  .feature-header-two {
    @include MaxMQ(sm) {
      font-size: 32px !important;
    }
  }
  .feature-image-two {
    @include MaxMQ(sm) {
      display: none;
    }
  }
  .feature-image-two-mobile {
    display: none;
    @include MaxMQ(sm) {
      display: block;
    }
  }
}
