@import '../configuration/config';

.about-container {
  max-width: $app-max-width;
  @include MaxMQ(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .intro-section {
    margin-top: 40px;
    border-bottom: 1px solid black;
    .intro {
      font-size: 24px;
      line-height: 2;
      @include MaxMQ(sm) {
        font-size: 18px !important;
      }
    }
  }
  .team-mebers {
    @include MaxMQ(sm) {
      justify-content: center !important;
    }
  }
  .team-members-container {
    margin-bottom: 100px;
    @include MaxMQ(sm) {
      margin-bottom: 0px;
    }
    .team-member-header {
      @include MaxMQ(sm) {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
    .team-member-header > p {
      font-size: 46px;
      @include MaxMQ(sm) {
        font-size: 32px;
      }
    }
  }
}
