@import "../configuration/config";

.advantages-card {
  width: 450px;
  height: 350px;
  @include MaxMQ(md) {
    width: 100%;
    height: auto;
  }
  .advantages-icon {
    width: 80px;
    height: 80px;
  }
  .advantages-description {
    overflow: auto;
    p {
      font-family: $primary-heading-font-family;
      line-height: 1.5;
    }
  }
  .advantages-description-bold {
    color: #6fa72f;
  }
}
