@import '../configuration/config';

.footer {
  width: 100%;
  height: 200px;
  .web-id-number {
    color: black;
    text-decoration: none;
  }
  @include MQ(xxl) {
    flex-shrink: 0;
  }
  @include MaxMQ(sm) {
    margin-top: 0px !important;
  }
}